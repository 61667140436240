<template>
  <div style="font-size: 16px">
    <div v-html="introduce.content"></div>
  </div>
</template>
<script>
import { getProductIntroduce } from '@/api/service'
export default {
  name: 'Introduce',
  data() {
    return {
      introduce: {},
      processId: undefined
    }
  },
  created() {
    this.processId = this.$route.query.productId
    this.onload()
  },
  methods: {
    onload() {
      getProductIntroduce({ productId: this.processId }).then((res) => {
        this.introduce = res.data
      })
    }
  },
}
</script>
<style lang="scss" scoped>
/deep/.div img {
  width: 100%;
}
/deep/.div iframe {
  width: 92%;
  margin: 0 4%;
  padding: 20px 0;
  border: 1px solid#fff;
}
</style>
